import React, { useState } from 'react';
import Headline from './Form/Headline';
import Field, { FIELD_TYPES } from './Form/Field';
import Button from '../common/Button';
import ThankYou from './ThankYou';
import Grid from '@material-ui/core/Grid';
import { useApplication } from '../../hooks/useApplication';
import { useMyBunac } from '../../hooks/useMyBunac';

const FORM_ID = 'employer_invite';

const formConfig = [
  {
    type: FIELD_TYPES.lineOfText,
    label: (
      <>
        <p>
          Please send the following email to your employer so that they can
          complete the Employer Application and Agreement form. It is imperative
          they complete this as soon as possible as they will be required to
          detail the internship placement and your training plan structure.
        </p>
        <p>
          Make sure you stay in touch with your employer and confirm that they
          have received this form. Your application cannot be processed without
          this. It is your responsibility to ensure that all documents are
          provided to BUNAC in a timely manner, including that your employer
          provides this information to BUNAC.
        </p>
        <p>
          Enter your employer email below and they will be sent a link to the
          Employer Application Form.
        </p>
      </>
    ),
  },
  {
    label: 'Employers Email',
    key: '2a81c6b2ee8cbdde80c415fcccd7945b1731d4d0',
    type: FIELD_TYPES.text,
    isRequired: true,
  },
  {
    type: FIELD_TYPES.lineOfText,
    label: (
      <>
        <br />
        <p>
          Please verify with your employer that they have a ratio of at least 5
          full-time staff members to 1 intern at the location you will be
          carrying out your internship. BUNAC cannot accept your employer if
          this ratio is not met.
        </p>
      </>
    ),
  },
];

export default ({ activities, pipelineId, onSubmit }) => {
  const { application } = useApplication();
  const [isFormValid, setIsFormValid] = useState(false);
  const { request } = useMyBunac();

  const [email, setEmail] = useState('');

  const handleChange = email => {
    setIsFormValid(email);
    setEmail(email);
  };

  const submit = async () => {
    const fields = {
      [formConfig[1].key]: email,
    };

    const note = `${window.location.origin}/webform-for-eaa?dealid=${application.id}`;

    const requestParams = {
      path: '/form',
      method: 'POST',
      params: {
        id: FORM_ID,
        dealid: application.id,
        pipelineid: pipelineId,
        notes: JSON.stringify([{ model: 'deal', html: note }]),
        fields: JSON.stringify(fields),
        activities: JSON.stringify(activities),
      },
    };

    try {
      await request(requestParams);
    } catch (error) {
      console.error(error.message);
    }
    await onSubmit();
  };

  return (
    <form>
      <Headline>Invite Employer</Headline>
      <Field config={formConfig[0]} />
      <Field config={formConfig[1]} value={email} onChange={handleChange} />
      <Field config={formConfig[2]} />
      <Grid container spacing={0}>
        <Grid item xs={12} align="right">
          <Button disabled={!isFormValid} submit onClick={submit}>
            Submit Information
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
