import React from 'react';
import styled from 'styled-components';

const Textarea = styled.textarea`
  height: 120px;
  width: 100%;
  color: rgba(0, 0, 0, 0.47);
  font-family: Arial;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 25px;
  padding: 8px;
`;

export default ({ children, ...props }) => (
  <Textarea {...props}>{children}</Textarea>
);
