import React from 'react';
import { Grid, Checkbox, InputAdornment, FormControlLabel } from '@material-ui/core';
import Select from './Select';
import TextField from './TextField';
import styled from 'styled-components';
import { Currencies } from '../../config/currency';
import createPersistedState from 'use-persisted-state';
import Textarea from '../../common/Textarea';

const useCurrency = createPersistedState('currency');

const CURRENCY_SYMBOLS = {};
Currencies.supported.forEach(({ code, symbol }) => {
  CURRENCY_SYMBOLS[code] = symbol;
});

const Headline = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Arial;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 30px;
  margin-bottom: 16px;
`;
const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const FIELD_TYPES = {
  text: 'varchar',
  phone: 'phone',
  select: 'enum',
  switch: 'boolean',
  date: 'date',
  checkbox: 'set',
  time: 'time',
  currency: 'currency',
  textarea: 'textarea',
  lineOfText: 'lineOfText',
  scrollingTextarea: 'scrollingTextarea',
  contentSublineOfText: 'contentSublineOfText',
  headline: 'headline',
  address: 'address',
};

const DATA_MODELS = {
  person: 'person',
  deal: 'deal',
  organization: 'organization'
};

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const getSwitchOptionMap = ({ options }) => {
  const optionMap = {
    left: null,
    right: null,
  };

  options.forEach((option, index) => {
    switch (option.label) {
      case 'No':
        optionMap.left = option;
        break;
      case 'Yes':
        optionMap.right = option;
        break;
      default: {
        if (index === 0) {
          optionMap.left = option;
        } else if (index === 1) {
          optionMap.right = option;
        }
      }
    }
  });

  return optionMap;
};

const Field = ({ value, config, formData, onChange }) => {
  const [activeCurrency] = useCurrency(Currencies.default);

  const handleSwitchChange = ({ booleanOptionMap }) => {
    let isCurrentlyChecked = false;
    if (
      booleanOptionMap.right &&
      formData[config.key] === booleanOptionMap.right.id
    ) {
      isCurrentlyChecked = true;
    }

    const value = isCurrentlyChecked
      ? booleanOptionMap.left.id
      : booleanOptionMap.right.id;
    onChange(value);
  };

  const renderOptions = ({ options }) => {
    return options.map(option => {
      return (
        <option key={`${config.key}_${option.id}`} value={option.id}>
          {option.label}
        </option>
      );
    });
  };

  const getLabel = () => {
    return (
      <Label>
        <span>{config.label || config.name}</span>
        {config.isRequired && <span>*</span>}
      </Label>
    );
  };

  const renderField = () => {
    if (config.isHidden && config.isHidden === true) {
      return <></>;
    }
    switch (config.type) {
      case FIELD_TYPES.text:
      case 'text':
      case FIELD_TYPES.phone:
      case FIELD_TYPES.address: {
        return (
          <Grid container spacing={2} key={config.key}>
            <Grid item xs={12} md={8}>
              <TextField
                variant="outlined"
                label={config.label || config.name}
                required={config.isRequired}
                fullWidth
                size="small"
                value={value}
                onChange={event => onChange(event.target.value)}
              />
            </Grid>
          </Grid>
        );
      }
      case FIELD_TYPES.textarea: {
        return (
          <Grid container spacing={2} key={config.key}>
            <Grid item xs={12} md={4}>
              {getLabel()}
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                multiline
                rows={3}
                rowsMax={10}
                variant="outlined"
                fullWidth
                size="small"
                value={value}
                readonly={config.readonly ? true : false}
                onChange={event => onChange(event.target.value)}
              />
            </Grid>
          </Grid>
        );
      }
      case FIELD_TYPES.select: {
        return (
          <Grid container spacing={2} key={config.key}>
            <Grid item xs={12} md={8}>
              <Select
                native
                labelId={config.key}
                required={config.isRequired}
                variant="outlined"
                size="small"
                fullWidth
                value={value}
                onChange={event => onChange(event.target.value)}
              >
                <option disabled selected key="noselection" value={null}>
                  {config.label || 'Please select'}{config.isRequired ? ' *' : ''}
                </option>
                {renderOptions({ options: config.options })}
              </Select>
            </Grid>
          </Grid>
        );
      }
      case FIELD_TYPES.switch: {
        const booleanOptionMap = getSwitchOptionMap({
          options: config.options,
        });
        return (
          <FormControlLabel key={config.key} control={
            <Checkbox
              fullWidth
              variant="outlined"
              checked={
                !(
                  !formData[config.key] ||
                  formData[config.key] === booleanOptionMap.left.id
                )
              }
              onChange={event => handleSwitchChange({ booleanOptionMap })} />
            }
            label={config.label || config.name }
          />
        );
      }
      case FIELD_TYPES.date: {
        return (
          <Grid container spacing={2} key={config.key}>
            <Grid item xs={12} md={4}>
              {getLabel()}
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                type="date"
                variant="outlined"
                size="small"
                value={value}
                onChange={event => onChange(event.target.value)}
              />
            </Grid>
          </Grid>
        );
      }
      case FIELD_TYPES.time: {
        return (
          <Grid container spacing={2} key={config.key}>
            <Grid item xs={12} md={4}>
              {getLabel()}
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                type="time"
                variant="outlined"
                size="small"
                onChange={event => onChange(event.target.value)}
              />
            </Grid>
          </Grid>
        );
      }
      case FIELD_TYPES.checkbox: {
        return (
          <CheckboxContainer container spacing={2} key={config.key}>
            <Grid item xs={12} md={7}>
              {getLabel()}
            </Grid>
            <Grid item xs={12} md={1}>
              <Checkbox
                fullWidth
                variant="outlined"
                onChange={event => onChange(event.target.checked)}
              />
            </Grid>
          </CheckboxContainer>
        );
      }
      case FIELD_TYPES.currency: {
        return (
          <Grid container spacing={2} key={config.key}>
            <Grid item xs={12} md={4}>
              {getLabel()}
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                value={value}
                onChange={event => onChange(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {activeCurrency}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        );
      }
      case FIELD_TYPES.lineOfText: {
        return (
          <Grid style={config.style} container key={config.key}>
            <Grid item xs={12}>
              {getLabel()}
            </Grid>
          </Grid>
        );
      }

      case FIELD_TYPES.contentSublineOfText: {
        return (
          <Grid style={config.style} container key={config.key}>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={8}>
              {getLabel()}
            </Grid>
          </Grid>
        );
      }
      case FIELD_TYPES.scrollingTextarea: {
        return (
          <Grid style={config.style} container key={config.key}>
            <Grid item xs={12}>
              <Textarea rows={6} readOnly>
                {config.label}
              </Textarea>
            </Grid>
          </Grid>
        );
      }
      case FIELD_TYPES.headline: {
        return (
          <Grid style={config.style} container key={config.key}>
            <Grid item xs={12}>
              <Headline>{getLabel()}</Headline>
            </Grid>
          </Grid>
        );
      }
      default: {
        console.log('invalid field type - config: ', config);
        return <></>;
      }
    }
  };

  if (config) {
    return renderField();
  } else {
    return null;
  }
};

export default Field;
export { Field, FIELD_TYPES, DATA_MODELS, getSwitchOptionMap };
